.Reasons {
  padding: 0 12rem;
  display: flex;
  gap: 2rem;
}

.left-side {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}

.left-side>img {
  object-fit: cover;
}

.left-side>:nth-child(1) {
  width: 12rem;
  grid-row: 1/3;
  height: 28rem;
}

.left-side>:nth-child(2) {
  width: auto;
  height: 16rem;
  grid-column: 2/13;
}

.left-side>:nth-child(3) {
  width: 14rem;
  grid-column: 2/3;
  grid-row: 2;
}

.left-side>:nth-child(4) {
  width: 10rem;
  height: 11.2rem;
  grid-row: 2;
  grid-column: 3/4;
}

.right-side {
  flex: 1 1;
  text-transform: uppercase;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
}

.right-side>span {
  font-weight: bold;
  color: var(--orange);
}

.right-side>div {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details>div {
  display: flex;
  font-size: 1rem;
  gap: 1rem;
  align-items: center;
}

.details>div>img {
  width: 2rem;
  height: 2rem;
}

.partners {
  display: flex;
  gap: 1rem;
}

.partners>img {
  width: 2.5rem;
}

@media screen and (max-width: 768px) {
  .Reasons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .left-side {
    grid-auto-rows: auto;
    overflow: hidden;
    margin-left: 140px;
  }

  .left-side>:nth-child(1) {
    width: 7rem;
    height: 17rem;
  }

  .left-side>:nth-child(2) {
    width: 15rem;
    height: 10rem;
  }

  .left-side>:nth-child(3) {
    width: 7rem;
  }

  .left-side>:nth-child(4) {
    width: 7rem;
    height: 6rem;
  }

  .partners {
    justify-content: space-between;
  }
}