.plans-container {
  display: flex;
  gap: 4rem;
  flex-direction: column;
  padding: 0 2rem;
  margin-top: -2rem;
  position: relative;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.plan {
  display: flex;
  flex-direction: column;
  background-color: #808080;
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 18rem;
}

.plan:hover {
  background-color: orange;
  cursor: pointer;
}

.plan>:nth-child(2) {
  background-color: var(--planCard);
  transform: scale(1.1);
}

.plan>svg {
  fill: var(--organge);
  width: 2rem;
  height: 2rem;
}

.plan>:nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}

.plan>:nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}

.plan>:nth-child(5) {
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.plan>:nth-child(5)>img {
  width: 1rem;
  height: 0.8rem;
  display: flex;
}

.feature {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 5px;
}

.feature>img {
  width: 1.2rem;
  color: white;
}

.plans>:nth-child(2)>svg {
  fill: white;
}

.plans>:nth-child(2)>button {
  color: orange;
}

.plans-blur-1 {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  left: 0rem;
}

.plans-blur-2 {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0rem;
}

@media screen and (max-width: 768px) {
  .plans-container {
    margin-top: 10px;
    flex-direction: column;
  }

  .plans {
    flex-direction: column;
  }
}