.Subscribe {
  display: flex;
  padding: 2rem 10rem;
  gap: 15rem;
}

.sub-left>:nth-child(1) {
  border: 2px solid orange;
  width: 14rem;
  border-radius: 20%;
  position: absolute;
}

.sub-left {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  margin: -10px 0;
}

.sub-right {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.email-container {
  display: flex;
  gap: 3rem;
  background-color: gray;
  padding: 1rem 2rem;
}

.email-container>input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
  font-size: 1.5rem;
}

::placeholder {
  color: var(--lightgray);
}

.btn-j {
  background-color: orange;
  color: white;
}

@media screen and (max-width: 768px) {
  .Subscribe {
    flex-direction: column;
    /* gap: 1rem; */
  }

  .sub-left {
    font-size: large;
    flex-direction: column;
    right: 100px;
  }
}